@import "mixins"
.mainContent
  background: var(--body_bg)
  min-height: 100%
  > :global(.loader)
    position: static
    height: var(--withoutHeaderHeight)
.accountView
  display: flex
  flex-direction: column
  :global(.no-data)
    &:before
      content: var(--noData_content)
      width: 136px
      height: 100px
      background: var(--icon-no-data) no-repeat
      background-size: 136px 100px
      margin: -60px 0 30px