@import "mixins"
.liveHeader
  background: var(--live_headerBg)
  padding: var(--live_headerPadding)
  position: sticky
  top: var(--headerHeight)
  z-index: 4
  border-bottom: var(--live_headerBorder)
  + div
    min-height: var(--withHeaderHeight)
    position: relative

.liveTabs
  background: var(--live_tabsBg)
  padding: 1px
  border-radius: var(--live_tabBorderRadius)
  @include flex

  > *:not(.favoritesTab)
    justify-content: center
    height: var(--live_tabHeight)
    min-width: 60px
    padding: 0 8px
    text-align: center
    flex: 1

    &.active
      box-shadow: var(--live_tabActiveShadow)
      color: var(--live_tabActiveColor)
      border-radius: var(--live_tabBorderRadius)
      background: var(--live_tabActiveBg)

  > a
    @include flex
    white-space: nowrap
    min-width: 80px
    @include bold

    > span
      @include bold

  > span
    @include flex
    flex-direction: column
    @include bold
    font-size: 13px

    > span:not(.favoritesStar):not(.favoritesCount)
      font-size: 9px
      text-transform: uppercase
      margin-top: 3px

.liveTab.active
  background: var(--live_tabLiveActiveBg)
  color: var(--live_tabLiveActiveColor)

.favoritesTab
  position: relative
  margin-left: 7px
  background: transparent
  @include flex
  flex-direction: column
  border-radius: 3px
  min-width: 60px
  padding: 0 8px

  &:before
    content: ""
    @include separator(var(--live_tabsSeparator))
    position: absolute
    height: 30px
    left: -12px
    top: 50%
    transform: translateY(-50%)

  .favoritesCount
    font-size: 11px
    color: rgb(var(--primaryColor), 0.6)
    margin: 0 -32px -8px 0

  .favoritesStar
    margin-left: -14px
    opacity: 0.8
  &:disabled
    opacity: 1
    svg
      filter: grayscale(100%)
      opacity: 0.5

  &.active
    background: var(--live_favoritesTabBg)
    .favoritesCount
      color: rgb(var(--secondaryColor))
    .favoritesStar
      opacity: 1

@media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 900px)
  .liveHeader
    position: unset
