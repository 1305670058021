@import "mixins"
.active
  color: var(--footer_linkActive)!important

.socialLink
  background: var(--footer_socialLinkBg)
  width: 30px
  height: 30px
  border-radius: 30px
  @include flex
  justify-content: center

  svg path
    fill: var(--svgIcon_fill)

  &:active
    opacity: 0.8

  &:not(:last-of-type)
    margin-right: 10px
